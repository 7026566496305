<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row" style="padding-bottom: 14px">
				<div class="col-md-4">
					<b>Cliente </b>
					<a-icon type="reload" @click="onReloadCustomers" :style="{ fontSize: '14px', color: 'red' }" v-if="isNewRecord" />
					<a-select
						class="text-uppercase"
						show-search
						option-filter-prop="children"
						:filter-option="filterOption"
						style="width: 100%"
						:disabled="!isNewRecord"
						v-model="theOrder.customer_id"
						@change="onSelectCustomer"
					>
						<a-select-option class="text-uppercase" v-for="(element, index) in customersList" :key="index" :value="element.id">{{ element.full_name }}</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4">
					<b>Dirección de entrega</b>
					<a-select class="text-uppercase" v-model="theOrder.customer_address_id" style="width: 100%" :disabled="!isNewRecord">
						<a-select-option class="text-uppercase" v-for="(element, index) in customerAddresses" :key="index" :value="element.id">{{ element.description }}</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4" v-if="theOrder.delivery_date">
					<b>Fecha tentativa de entrega</b>
					<a-date-picker
						v-model="theOrder.delivery_date"
						:disabled-date="disabledDate"
						:value-format="'YYYY-MM-DD'"
						:format="'DD-MM-YYYY'"
						placeholder="Selecciona la fecha"
						style="width: 100%"
						:disabled="!isEditable"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 text-left">
					<b>Forma de pago </b>
					<a-select style="width: 100%" :disabled="!isEditable" v-model="theOrder.payment_method">
						<a-select-option value="PUE">Pago en una sola exhibición</a-select-option>
						<a-select-option value="PPD">Pago diferido</a-select-option>
						<a-select-option value="NEXT">Pago a crédito</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4 text-left" v-if="theOrder.payment_method == 'PPD'">
					<b>Parcialidades</b>
					<a-select style="width: 100%" :disabled="!isEditable" v-model="theOrder.partialities" @change="onChangePartialities">
						<a-select-option value="2">2 pagos</a-select-option>
						<a-select-option value="3">3 pagos</a-select-option>
						<a-select-option value="4">4 pagos</a-select-option>
					</a-select>
				</div>
				<div class="col-md-4 text-left" v-if="theOrder.payment_method == 'NEXT'">
					<b>Fecha de pago</b>
					<a-date-picker
						v-model="theOrder.credit_payment_date"
						:disabled-date="disabledDate"
						:value-format="'YYYY-MM-DD'"
						:format="'DD-MM-YYYY'"
						placeholder="Selecciona la fecha"
						style="width: 100%"
						:disabled="!isEditable"
					/>
				</div>
				<div class="col-md-4" v-if="isEditable">
					<b>Productos</b>
					<a-input-search v-model="searchText" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" data-content="Pedido" />
				</div>
			</div>
			<div v-if="isEditable">
				<div v-for="(element, index) in theOrder.elements" :key="index">
					<div class="row" v-if="index > 0">
						<div class="col-12">
							<hr class="hrDotted" />
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<h4 class="text-muted">
								<b>#{{ index + 1 }}</b> - {{ element.description }}
							</h4>
							<div>
								<b>SKU: {{ element.sku }}</b>
							</div>
						</div>
						<div class="col-md-2">
							<b>Cantidad</b>
							<a-input v-model="element.quantity" :disabled="!isEditable" @change="onChangeElements(index)" />
						</div>
						<div class="col-md-2">
							<b>Precio unitario</b>
							<a-input prefix="$" v-money="money" v-model="element.price" :disabled="!isEditable" @change="onChangeElements(index)" />
						</div>
						<div class="col-md-2">
							<b>Importe</b>
							<a-input prefix="$" v-money="money" v-model="element.amount" disabled />
						</div>
					</div>
					<div class="row pt14">
						<div class="col-md-12 text-right">
							<span class="pointer" @click="onDeleteElement(index)">Eliminar partida</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="theOrder.elements" rowKey="id" :pagination="false">
						<div slot="sku" slot-scope="record">
							{{ record.sku | replaceIfLogicalFalse('-') }}
						</div>
						<div slot="description" slot-scope="record">
							<div class="text-left">{{ record.quantity }}x {{ record.description }}</div>
						</div>
						<div slot="amount" slot-scope="record" class="text-right">{{ numeral(record.price).format('$0,0.00') }}</div>
						<div slot="total" slot-scope="record" class="text-right">
							{{ numeral(numeral(record.price).value() * numeral(record.quantity).value()).format('$0,0.00') }}
						</div>
					</a-table>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrDotted" />
					<div style="text-align: right; font-size: 30px">
						Subtotal: {{ numeral(getSubtotal).format('$0,0.00') }}<br />
						IVA: {{ numeral(getIVA).format('$0,0.00') }}<br />
						Total: {{ numeral(getTotal).format('$0,0.00') }}
					</div>
				</div>
			</div>
			<div class="row pt20" v-if="theOrder.payment_method == 'PPD'">
				<div class="col-md-12">
					<hr class="hrText" data-content="Desglose de pagos diferidos" />
				</div>
				<div :class="partialitiesClass" v-for="(element, index) in partialities" :key="index">
					<div class="text-center" style="font-size: 30px">Pago #{{ index + 1 }}</div>
					<hr />
					<div>
						<b>Total <small>(con IVA)</small></b>
						<a-input prefix="$" v-money="money" v-model="element.total" :disabled="!isEditable" @change="onChangeTotalPPD(index, $event)" />
					</div>
					<div class="pt10">
						<b>IVA</b>
						<a-input prefix="$" v-money="money" :value="element.iva" disabled />
					</div>
					<div class="pt10">
						<b>Monto <small>(sin IVA)</small></b>
						<a-input prefix="$" v-money="money" :value="element.amount" disabled />
					</div>
					<div class="pt10">
						<b>Fecha de pago</b>
						<a-date-picker
							v-model="element.payment_date"
							:disabled-date="disabledDate"
							:value-format="'YYYY-MM-DD'"
							:format="'DD-MM-YYYY'"
							:disabled="!isEditable"
							@change="onChangePaymentDate(index, $event)"
							placeholder="Selecciona la fecha"
							style="width: 100%"
						/>
					</div>
					<div v-if="Number(actualPurchaseOrder.status) >= 1">
						<div v-if="index == 0 || partialities[index - 1].paymentReceipt">
							<hr class="hrText" data-content="Pago" />
							<b>Comprobante</b>
							<div>
								<a-upload v-if="!element.paymentReceipt" name="file" accept="application/pdf,image/*" :file-list="filePayment" :remove="handleRemovePayment" :before-upload="beforeUploadPayment">
									<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
								</a-upload>
								<div>
									<a-button @click="getSecureURL(element.paymentReceipt, 'show')"> <a-icon type="file" /> Visualizar </a-button>
									<div v-if="getFileBy('paymentReceipt', index) && getFileBy('paymentReceipt', index).created_at">
										<b>Pagado:</b>
										<div>{{ moment(getFileBy('paymentReceipt', index).created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="(index == 0 && element.paymentReceipt) || (element.paymentReceipt && partialities[index - 1].pdf)">
						<hr class="hrText" data-content="Complemento de pago" />
						<div>
							<div>
								<div>
									<b>Archivo PDF</b>
									<br />
									<a-upload v-if="!element.pdf" name="file" accept="application/pdf" :file-list="filePDF" :remove="handleRemovePDF" :before-upload="beforeUploadPDF">
										<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
									</a-upload>
									<a-button v-else @click="getSecureURL(element.pdf, 'show')"> <a-icon type="file" /> Visualizar </a-button>
								</div>
								<div class="pt10">
									<b>Archivo XML</b>
									<br />
									<a-upload v-if="!element.xml" name="file" accept="text/xml" :file-list="fileXML" :remove="handleRemoveXML" :before-upload="beforeUploadXML">
										<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
									</a-upload>
									<a-button v-else-if="element.xml" @click="getSecureURL(element.xml, 'download')"> <a-icon type="download" /> Descargar </a-button>
									<div v-else>- No establecido -</div>
								</div>
								<div class="pt10 text-center" v-if="!element.pdf && !element.xml">
									<a-button class="ml5 btn btn-success" icon="file" type="success" @click="requestValidation">Adjuntar factura</a-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<hr class="hrText" />
			</div>
			<div class="row">
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<!-- BOTONES POR ESTATUS -->
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success ml7" @click="onCreateOrder" v-if="isEditable">Guardar</a-button>
					<a-button icon="file" class="btn btn-warning ml7" @click="onCloseQuotation" v-if="!isNewRecord && isEditable && allowCloseQuotation">Cerrar cotización</a-button>
					<!-- <a-button class="ml5 btn btn-primary" icon="backward" @click="onChangeStatus(0)" v-if="Number(actualPurchaseOrder.status) == 1 || Number(actualPurchaseOrder.status) == 2">
						Regresar ODC a cotización
					</a-button>
					<a-button class="ml5 btn btn-primary" icon="backward" @click="onChangeStatus(-2)" v-if="Number(actualPurchaseOrder.status) == 3"> Regresar ODC a pagadas</a-button>
					<a-button class="ml5 btn btn-success" icon="save" type="success" @click="onCreateOrder" v-if="isNewRecord || !actualPurchaseOrder.blocked">Generar cotización</a-button>
					<a-button
						class="ml5 btn btn-success"
						icon="forward"
						type="success"
						@click="updatePaymentReceipt"
						v-if="Number(actualPurchaseOrder.status) == 1 || Number(actualPurchaseOrder.status) == 1.1"
						>{{ theOrder.payment_method == 'PPD' ? 'Adjuntar comprobante de pago' : 'Pasar ODC a pagadas' }}</a-button
					>
					<a-button class="ml5 btn btn-success" icon="forward" type="success" @click="requestValidation" v-if="Number(actualPurchaseOrder.status) == 2">Pasar ODC a facturadas</a-button>
					<a-button class="ml5 btn btn-success" icon="forward" type="success" @click="setValidation" v-if="userPermition && Number(actualPurchaseOrder.status) == 3">Pasar ODC a validadas</a-button>
					<a-button class="ml5 btn btn-success" icon="down-square" type="success" @click="onChangeStatus(6)" v-if="Number(actualPurchaseOrder.status) == 4">ODC en sucursal</a-button> -->
				</div>
			</div>
		</a-spin>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.products.visible" title="Listado de Productos" :closable="true" @cancel="onCloseModal('products')" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('products')"> Cerrar </a-button>
			</template>
			<productsListComponent @selectedProduct="onSelectProduct" />
		</a-modal>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Comprobante" :closable="true" @cancel="handleCancelModalFile">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" v-if="modalFileVisible" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar archivo</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import { purchaseOrders, cfdiUseList } from '@/constants'
import _ from 'lodash'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import fileViewerComponent from '@/components/fileViewer'
import xml2js from 'xml2js'
//
import productsListComponent from '../productsList'

export default {
	name: 'insertOrUpdateImpressment',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		productsListComponent,
		fileViewerComponent,
	},
	data() {
		return {
			theOrder: {
				customer_id: '',
				customer_address_id: '',
				delivery_date: '',
				payment_method: 'PUE',
				payment_date: '',
				partialities: '2',
				elements: [],
			},
			searchText: '',
			customerAddresses: [],
			modal: {
				products: {
					visible: false,
				},
			},
			//
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			fileList: [],
			filePDF: [],
			fileXML: [],
			filePayment: [],
			//
			modalSupplier: {
				visible: false,
				selectedSupplier: null,
			},
			showXML: false,
			searchIndex: 0,
			generalDiscount: 0,
			discountType: '',
			columns: [
				{
					title: 'SKU',
					align: 'center',
					scopedSlots: { customRender: 'sku' },
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'amount' },
				},
				{
					title: 'Total',
					align: 'center',
					scopedSlots: { customRender: 'total' },
				},
			],
			partialities: [],
			partialitiesClass: '',
			cfdiUseList,
			destination: '',
			filePreXML: [],
		}
	},
	computed: {
		...mapGetters('suppliers', ['suppliersList', 'actualSupplier']),
		...mapGetters('customers', ['customersList']),
		...mapGetters('purchaseOrders', ['actualPurchaseOrder', 'modalFileVisible', 'fileURL', 'isCashODC', 'fromRLS']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		...mapGetters('products', ['productsList', 'spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('user', ['user']),
		...mapGetters('taxRegimes', ['taxRegimesList']),
		...mapGetters('impressment', ['actualImpressment']),
		allowCloseQuotation() {
			if (utilities.objectValidate(this.theOrder, 'elements', []).length) {
				return !this.theOrder.elements.filter((e) => e.available && numeral(e.price).value() == 0).length
			} else {
				return false
			}
		},
		getGrandTotal() {
			return numeral(this.getTotal).format('$0,0.00')
		},
		userPermition() {
			//
			// zem - 17
			// pozos - 11
			// ismael - 1
			let allowedUserPermition = [1, 11, 17]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		isNewRecord() {
			return !!!utilities.objectValidate(this.$route, 'params.id', false)
		},
		isEditable() {
			return this.isNewRecord || this.actualImpressment.status == 0
		},
		getIVA() {
			return (this.getTotal / 1.16) * 0.16
		},
		getSubtotal() {
			return this.getTotal / 1.16
		},
		getTotal() {
			let total = 0

			this.theOrder.elements.forEach((element, index) => {
				total += numeral(element.quantity).value() * numeral(element.price).value()
			})

			return total
		},
		hasInvoice() {
			if (Number(this.paymentData.payment_method) == 1) {
				return !!this.filePDF.length || this.getFileBy('pdf')
			} else {
				return !!((this.filePDF.length || this.getFileBy('pdf')) && (this.fileXML.length || this.getFileBy('xml')))
			}
		},
		getTotalDiscount() {
			return numeral(this.getTotal / 1.16).value() - numeral(this.generalDiscount).value()
		},
		getIVADiscount() {
			return this.getTotalDiscount * 0.16
		},
		hasPDf() {
			return this.fileURL.toLowerCase().split('.pdf').length == 2 ? true : false
		},
		isForeignSupplier() {
			return !!utilities.objectValidate(this.actualSupplier, 'foreign_supplier', false)
		},
		hasDestination() {
			return this.actualPurchaseOrder.from_panel == 0 && this.actualPurchaseOrder.from_pacific == 0 && this.actualPurchaseOrder.from_rls == 0 ? false : true
		},
		totalPartialities() {
			return numeral(
				numeral(
					this.partialities.reduce((previousValue, currentValue) => {
						return previousValue + numeral(currentValue.total).value()
					}, 0),
				).format('0,0.00'),
			).value()
		},
	},
	beforeMount() {
		if (!this.customersList.length) {
			this.onReloadCustomers()
		}
	},
	mounted() {
		if (!this.isNewRecord) {
			this.$store.dispatch('impressment/GET_ONE', this.$route.params.id)
		}
	},
	methods: {
		moment,
		numeral,
		onChangeAvailability(index) {
			this.theOrder.elements[index].available = !this.theOrder.elements[index].available
		},
		onChangeElements(index) {
			// if (this.theOrder.elements[index].quantity > this.theOrder.elements[index].stock) {
			// 	Swal.fire({
			// 		title: 'Cotizaciones',
			// 		html: `No puedes añadir más piezas de las disponibles (${this.theOrder.elements[index].stock})`,
			// 		icon: 'error',
			// 		confirmButtonText: 'Ok',
			// 	})
			// 	this.theOrder.elements[index].quantity = _.clone(this.theOrder.elements[index].stock)
			// 	return false
			// }
			this.theOrder.elements[index].amount = numeral(numeral(this.theOrder.elements[index].price).value() * numeral(this.theOrder.elements[index].quantity).value()).format('0,0.00')
		},
		onCloseModal(section) {
			this.modal[section].visible = false
		},
		onSelectProduct(product) {
			// console.log('onSelectProduct-->', product)
			// return false
			let quantity = utilities.objectValidate(product, 'quantity', 1)
			let index = this.theOrder.elements.findIndex((e) => e.sku == product.sku)

			if (index == -1) {
				this.theOrder.elements.push({
					quantity,
					description: product.label,
					sku: product.sku,
					price: numeral(product.normal_price).format('0,0.00'),
					amount: numeral(product.normal_price).format('0,0.00'),
					available: !!utilities.objectValidate(product, 'stock', 0) > 0,
					stock: utilities.objectValidate(product, 'stock', 0),
				})
			} else {
				let allElements = _.cloneDeep(this.theOrder.elements)
				let element = _.cloneDeep(allElements[index])
				quantity = element.quantity + 1
				let amount = numeral(numeral(element.price).value() * quantity).format('0,0.00')
				this.theOrder.elements = []

				allElements[index] = _.cloneDeep({
					...element,
					quantity,
					description: product.label,
					sku: product.sku,
					amount,
				})
				this.theOrder.elements = allElements
			}
			this.searchText = ''
			this.onCloseModal('products')

			// recargamos los pagos cada vez que se solicita un nuevo producto
			if (this.theOrder.payment_method == 'PPD') {
				this.onChangePartialities(this.theOrder.partialities)
			}
		},
		disabledDate(current) {
			// Can not select days before today and today
			return current && current < moment().endOf('day')
		},
		onChangeODCType(e) {
			switch (e) {
				case 'rls':
					this.$store.commit('purchaseOrders/SET_STATE', {
						isCashODC: 0,
						fromRLS: 1,
					})
					break
				case 'pacific':
					this.$store.commit('purchaseOrders/SET_STATE', {
						isCashODC: 0,
						fromRLS: 0,
					})
					break
				case 'panel':
					this.$store.commit('purchaseOrders/SET_STATE', {
						isCashODC: 1,
						fromRLS: 0,
					})
					break
			}
		},
		addElement(requireConfirmation) {
			if (requireConfirmation) {
				let errors = []
				this.theOrder.elements.forEach((element, index) => {
					let item = index + 1
					if (element.quantity == '') {
						errors.push(`- Se requiere cantidad en la partida #${item}`)
					}
					if (element.measurement_unit == '') {
						errors.push(`- Se requiere unidad de medida en la partida #${item}`)
					}
					if (element.description == '') {
						errors.push(`- Se requiere descripción en la partida #${item}`)
					}
					if (numeral(element.unit_cost).value() == 0) {
						errors.push(`- Se requiere costo unitario en la partida #${item}`)
					}
				})
				if (errors.length) {
					Swal.fire({
						title: 'Cotizaciones',
						html: errors.join('<br>'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
					return false
				}
			}
			this.theOrder.elements.push(_.cloneDeep(purchaseOrders.order))
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		confirm() {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas autorizar el gasto de esta ODC?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status: 1,
					})
				}
			})
		},

		updatePaymentReceipt() {
			let errors = []
			if (this.filePayment.length == 0) {
				errors.push(`- Debes adjuntar comprobante de pago`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que he realizado el pago correspondiente de la ODC`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					const { filePayment } = this
					let payment_method = this.actualPurchaseOrder.is_tax ? 2 : JSON.parse(this.actualPurchaseOrder.payment_method_data).payment_method
					let field = 'paymentReceipt'
					let totalPayment = this.actualPurchaseOrder.total - this.actualPurchaseOrder.total_impuestos_retenidos

					let formDataPayment = new FormData()
					formDataPayment.append('file', filePayment[0])
					formDataPayment.append('isForeignSupplier', Boolean(this.isForeignSupplier))
					formDataPayment.append('is_tax', Boolean(this.actualPurchaseOrder.is_tax))
					formDataPayment.append('purchase_order_id', this.$route.params.id)
					formDataPayment.append('payment_method', payment_method)

					if (this.theOrder.payment_method == 'PPD') {
						let paymentReceipts = this.partialities.filter((e) => e.paymentReceipt).length || 0
						formDataPayment.append('index_ppd_file', paymentReceipts)
						totalPayment = this.partialities[paymentReceipts].total
					}

					formDataPayment.append('total', totalPayment)
					// indica si la ODC es de RLS
					formDataPayment.append('from_rls', this.fromRLS)

					this.$store.dispatch('purchaseOrders/UPLOAD_FILES', { field, formDataPayment, onlyReloadRecord: true })
				}
			})
		},
		setGlobalInvoice() {
			let errors = []
			if (this.filePDF.length == 0) {
				errors.push(`- Debes adjuntar archivo PDF de la factura general`)
			}

			if (this.filePDF.length == 0 || this.fileXML.length == 0) {
				errors.push(`- Debes adjuntar archivo XML de la factura general`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que los archivos son correspondientes a la ODC`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let formDataPDF = false
					let formDataXML = false
					let field = 'globalInvoice'

					if (this.filePDF.length) {
						const { filePDF } = this
						formDataPDF = new FormData()
						formDataPDF.append('file', filePDF[0])
						formDataPDF.append('purchase_order_id', this.$route.params.id)
					}

					if (this.fileXML.length) {
						const { fileXML } = this
						formDataXML = new FormData()
						formDataXML.append('file', fileXML[0])
						formDataXML.append('purchase_order_id', this.$route.params.id)
					}

					// console.log({ formDataPDF, formDataXML })
					this.$store.dispatch('purchaseOrders/UPLOAD_FILES', { field, formDataPDF, formDataXML, onlyReloadRecord: true })
				}
			})
		},
		requestValidation() {
			let errors = []

			if (this.theOrder.payment_method == 'PUE') {
				if (!this.actualPurchaseOrder.is_tax) {
					if (Number(this.actualPurchaseOrder.status) == 2 && !this.filePDF.length && !this.getFileBy('pdf')) {
						errors.push(`- Debes adjuntar factura, ticket de compra o vale azul`)
					}
					if (!this.isForeignSupplier && this.actualPurchaseOrder.status == 2 && Number(this.paymentData.payment_method) == 2 && !this.fileXML.length && !this.getFileBy('xml')) {
						errors.push(`- Debes adjuntar xml`)
					}
				}
			} else {
				//
				if (!this.filePDF.length && !this.getFileBy('pdf')) {
					errors.push(`- Debes adjuntar archivo PDF de la factura`)
				}
				if (!this.fileXML.length && !this.getFileBy('xml')) {
					errors.push(`- Debes adjuntar archivo XML de la factura`)
				}
			}

			// Validamos que seleccionen el tipo de gasto
			if (this.actualPurchaseOrder.status == 2 && !utilities.objectValidate(this.actualPurchaseOrder, 'expense_type', false)) {
				errors.push(`- Debes seleccionar el tipo de gasto`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que los comprobantes adjuntos corresponden a esta ODC`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let formDataPDF = false
					let formDataXML = false

					if (this.filePDF.length) {
						const { filePDF } = this
						formDataPDF = new FormData()
						formDataPDF.append('file', filePDF[0])
						formDataPDF.append('purchase_order_id', this.$route.params.id)
					}

					if (this.fileXML.length) {
						const { fileXML } = this
						formDataXML = new FormData()
						formDataXML.append('file', fileXML[0])
						formDataXML.append('purchase_order_id', this.$route.params.id)
					}

					if (this.theOrder.payment_method == 'PPD') {
						let paymentReceipts = this.partialities.filter((e) => e.pdf).length || 0
						formDataPDF.append('index_ppd_file', paymentReceipts)

						if (this.fileXML.length) {
							formDataXML.append('index_ppd_file', paymentReceipts)
						}
					}

					if (this.actualPurchaseOrder.is_tax) {
						this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
							id: this.$route.params.id,
							status: 3,
							expense_type: this.actualPurchaseOrder.status == 2 ? this.actualPurchaseOrder.status : false,
						})
					} else {
						if (this.actualPurchaseOrder.status == 2) {
							formDataPDF.append('expense_type', this.actualPurchaseOrder.expense_type)
						}

						this.$store.dispatch('purchaseOrders/REQUEST_VALIDATION', { formDataPDF, formDataXML, onlyReloadRecord: true })
					}
				}
			})
		},
		onCloseQuotation() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cerrar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Enviamos cotización
					await this.$store.dispatch('impressment/SEND_GENERATE_PDF', { id: this.$route.params.id, method: 'whatsapp' })

					// actualizamos cotización
					await this.$store
						.dispatch('impressment/UPDATE_STATUS', {
							id: this.$route.params.id,
							status: 5, // Enviadas
						})
						.then(async (response) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
								icon: 'success',
								confirmButtonText: 'Ok',
							}).then(() => {
								//
								this.$router.replace('/impressment')
							})
						})
						.catch(async (error) => {
							await Swal.fire({
								title: 'Cotizaciones',
								text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro. Intenta de nuevo.'),
								icon: 'error',
								confirmButtonText: 'Ok',
							})
						})
				}
			})
		},
		async onCreateOrder(showSuccessNotification = true) {
			let errors = []

			if (!this.theOrder.elements.length) {
				await Swal.fire({
					title: 'Cotizaciones',
					html: 'Ingresa al menos un producto a la cotización',
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return
			}

			if (!this.theOrder.customer_id) {
				errors.push(`- Se requiere seleccionar cliente`)
			}

			if (!this.theOrder.customer_address_id) {
				errors.push(`- Se requiere seleccionar dirección de entrega`)
			}

			if (this.theOrder.payment_method == 'PPD') {
				if (!this.partialities.length) {
					errors.push(`- Se requiere ingresar información de pagos y parcialidades`)
				}

				this.partialities.forEach((element, index) => {
					if (!moment(element.payment_date).isValid()) {
						errors.push(`- Ingresa la fecha de pago #${index + 1}`)
					}

					let prevValidation = this.onChangePaymentDate(index, element.payment_date, 'validate')
					if (prevValidation) {
						errors.push(prevValidation)
					}
				})

				if (numeral(this.getGrandTotal).value() !== this.totalPartialities) {
					errors.push(`- El monto ingresado por parcialidades no corresponde al total`)
				}
			}

			this.theOrder.elements.forEach((element, index) => {
				if (!element.quantity) {
					errors.push(`- Se requiere cantidad en la partida #${index + 1}`)
				}
			})

			if (this.theOrder.payment_method == 'NEXT') {
				if (!moment(this.theOrder.credit_payment_date).isValid()) {
					errors.push(`- Ingresa la fecha de pago del crédito`)
				}
				if (moment(this.theOrder.credit_payment_date).isSameOrBefore(moment(), 'day')) {
					errors.push(`- La fecha de pago no puede ser igual o menor a la actual`)
				}
			}

			if (errors.length) {
				await Swal.fire({
					title: 'Cotizaciones',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return
			}

			let result = await Swal.fire({
				title: 'Atención',
				html: `Confirmo que la información de la cotización es correcta`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			})

			if (result.isConfirmed) {
				let payload = {
					theOrder: this.theOrder,
					partialities: this.partialities,
				}
				let method = this.isNewRecord ? 'impressment/CREATE' : 'impressment/UPDATE'

				if (!this.isNewRecord) {
					payload = { ...payload, id: this.$route.params.id }
				}

				try {
					let response = await this.$store.dispatch(method, payload)
					if (showSuccessNotification) {
						await Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
					}
				} catch (error) {
					await Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				}
			}
		},
		beforeUpload(file) {
			this.fileList = [file]
			return false
		},
		beforeUploadPDF(file) {
			this.filePDF = [file]
			return false
		},
		beforeUploadXML(file) {
			this.fileXML = [file]
			return false
		},
		beforeUploadPayment(file) {
			this.filePayment = [file]
			return false
		},

		handleRemove() {
			this.fileList = []
		},
		handleRemovePDF() {
			this.filePDF = []
		},
		handleRemoveXML() {
			this.fileXML = []
		},
		handleRemovePayment() {
			this.fileXML = []
		},
		onCancel() {
			this.$router.replace('/impressment')
		},
		onReloadCustomers() {
			let payload = {}
			this.$store.dispatch('customers/GET', payload)
		},
		onSelectCustomer() {
			if (this.isNewRecord) {
				this.theOrder.customer_address_id = ''
			}

			this.$store.dispatch('customers/GET_ONE', this.theOrder.customer_id).then((response) => {
				// console.log('customers/GET_ONE::response->', response)
				this.customerAddresses = _.cloneDeep(response.addresses)
			})
		},
		setData(newData) {
			this.theOrder.elements = []
			newData.elements.forEach((e) => {
				this.theOrder.elements.push({
					...e,
					unit_cost: numeral(utilities.objectValidate(e, 'unit_cost', 0)).format('0,0.00'),
				})
			})
			this.theOrder.supplier = _.clone(newData.supplier_id)
			this.theOrder.payment_method = newData.sat_payment_method
			this.theOrder.advanceAmount = numeral(newData.advance_amount).format('0,0.00')
			this.theOrder.credit_payment_date = newData.credit_payment_date
			this.theOrder.cie = newData.cie
			this.theOrder.reference = newData.reference
			this.theOrder.area = newData.area
			this.theOrder.advance_ticket = utilities.objectValidate(newData, 'advance_ticket', 0) > 0 ? newData.advance_ticket : ''
			this.theOrder.from_loan = Boolean(utilities.objectValidate(newData, 'from_loan', false))
			this.theOrder.branch_office_id = utilities.objectValidate(newData, 'branch_office_id', '')
			this.generalDiscount = numeral(utilities.objectValidate(newData, 'general_discount', 0)).format('0,0.00')
			this.discountType = utilities.objectValidate(newData, 'discount_type', false) ? newData.discount_type : ''
			this.destination = newData.from_pacific ? 'pacific' : newData.from_panel ? 'panel' : 'rls'
			this.theOrder.deadline = newData.deadline

			if (newData.sat_payment_method == 'PPD') {
				// console.log('newData.ppd_payments-->', typeof newData.ppd_payments)
				let ppdPayments = _.cloneDeep(newData.ppd_payments)
				this.theOrder.partialities = ppdPayments.length.toString()
				this.partialities = _.cloneDeep(ppdPayments)
			}

			this.onSelectSupplier()
			setTimeout(() => {
				this.theOrder.payment_method_index = utilities.objectValidate(newData, 'payment_method_index', 0)
			}, 300)
		},
		validateURL(link) {
			return utilities.isValidURL(link)
		},
		getSecureURL(id, action) {
			this.$store.dispatch('purchaseOrders/GET_FILE_URL', { id, action })
		},
		getElementIVA(index) {
			let amount = numeral(this.theOrder.elements[index].unit_cost).value()
			let quantity = numeral(this.theOrder.elements[index].quantity).value()

			return numeral(((amount * quantity) / 1.16) * 0.16).format('0,0.00')
		},
		getElementTotal(index) {
			let amount = numeral(this.theOrder.elements[index].unit_cost).value()
			let quantity = numeral(this.theOrder.elements[index].quantity).value()

			return numeral((amount * quantity) / 1.16).format('0,0.00')
		},
		handleCancel(data) {
			if (data) {
				this.theOrder.supplier = data.id
				this.onSelectSupplier()
			}
			this.modalSupplier.visible = false
		},
		handleCancelModalFile() {
			//
			this.$store.commit('purchaseOrders/SET_STATE', {
				modalFileVisible: false,
			})
		},
		onAddSupplier() {
			this.theOrder.payment_method_index = ''
			this.$store.commit('suppliers/SET_STATE', {
				actualSupplier: {},
			})
			this.modalSupplier.visible = true
		},
		onDeleteElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar la partida con <b>"${this.theOrder.elements[index].description}"</b> de ODC?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.theOrder.elements.splice(index, 1)
				}
			})
		},
		getFileBy(type, index_ppd_file = false) {
			if (utilities.objectValidate(this.actualPurchaseOrder, 'files', false)) {
				if (index_ppd_file) {
					return this.actualPurchaseOrder.files.find((e) => e.type == type && e.index_ppd_file == index_ppd_file)
				} else {
					return this.actualPurchaseOrder.files.find((e) => e.type == type)
				}
			}
			return false
		},
		onSearch() {
			if (this.searchText == '' || this.searchText.length < 3) {
				Swal.fire({
					title: 'Cotizaciones',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.searchText, fromPOS: false })
			}
		},
		onChangeStatus(status) {
			//
			let message = ''
			switch (status) {
				case -2:
					message = '¿Deseas regresar ODC a "Pagadas"?'
					break
				case 0:
					message = '¿Deseas regresar ODC a "Cotización"?'
					break
				case 3:
					message = '¿Deseas validar los comprobantes de la ODC?'
					break
				case 4:
					message = '¿Deseas confirmar llegada de la ODC a almacén?'
					break
				default:
					message = ''
					break
			}
			Swal.fire({
				title: 'Atención',
				html: message,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
			}).then(async (result) => {
				if (result.value != '' && result.isConfirmed) {
					await this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status,
					})
				}
			})
		},
		onLinkPress(link) {
			window.open(link)
		},
		onChangePartialities(value) {
			let total = numeral(this.getGrandTotal).value()
			let partialities = []
			this.partialities = []
			value = numeral(value).value()

			for (let i = 1; i <= value; i++) {
				let amount = numeral(total / value / 1.16).format('0,0.00')
				let iva = numeral(numeral(amount).value() * 0.16).format('0,0.00')

				partialities.push({
					amount,
					iva,
					total: numeral(total / value).format('0,0.00'),
					paymentDate: null,
				})
			}
			setTimeout(() => {
				this.partialities = _.cloneDeep(partialities)
			})
		},
		onChangePaymentDate(index, value, method = 'inline') {
			// console.log('onChangePaymentDate-->', { index, value, method })

			if (index > 0 && moment(value).format('YYYY-MM-DD') <= moment(this.partialities[index - 1].payment_date).format('YYYY-MM-DD')) {
				if (method == 'inline') {
					Swal.fire({
						title: 'Cotizaciones',
						text: `- La fecha del pago #${index + 1} no puede ser menor o igual al pago previo`,
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				} else {
					return `- La fecha del pago #${index + 1} no puede ser menor o igual al pago previo`
				}
			}
		},
		onChangeTotalPPD(index, event) {
			// console.log('onChangeTotalPPD', { index, value: event.target.value })
			let totalAmount = numeral(event.target.value).value()
			this.partialities[index].amount = numeral(totalAmount / 1.16).format('0,0.00')
			this.partialities[index].iva = numeral((totalAmount / 1.16) * 0.16).format('0,0.00')
		},
		handleRemovePreXML() {
			this.filePreXML = []
		},
		handleXML(file) {
			const reader = new FileReader()
			reader.onload = (event) => {
				const xml = event.target.result
				this.convertXmlToJson(xml)
			}
			reader.readAsText(file)
			this.filePreXML = [file]
			return false
		},
		convertXmlToJson(xml) {
			xml2js.parseString(xml, (err, result) => {
				if (err) {
					console.error(err)
					return
				}
				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante'], false));

				// Validamos sobre los impuestos retenidos y el régimen del emisor
				if (utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], false)) {
					let paymentMethod = utilities.objectValidate(result, ['cfdi:Comprobante', '$', 'MetodoPago'], false)
					if (paymentMethod != 'PUE') {
						//
						let taxRegimeCode = utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Emisor', 0, '$', 'RegimenFiscal'], '- No se encuentra el dato -')
						let { code, description } = this.taxRegimesList.find((e) => e.code == taxRegimeCode)

						Swal.fire({
							title: 'Validación de XML',
							html: `El XML analizado debe contar con un método de pago dado como PUE debido al régimen que expresa se define como:<br><br>${code} - ${description}`,
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						this.actualPurchaseOrder = {}
						this.filePreXML = []
						return false
					}
					this.actualPurchaseOrder.total_impuestos_retenidos = utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], 0)
				}

				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Conceptos', 0], false));
				this.theOrder.elements = []
				let newElements = []

				utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Conceptos', 0, 'cfdi:Concepto'], []).forEach((e) => {
					if (utilities.objectValidate(e, '$', false)) {
						let element = e.$
						let quantity = numeral(element.Cantidad).value()

						let newElement = {
							description: element.Descripcion,
							measurement_unit: element.ClaveUnidad,
							quantity,
							amount: numeral(element.ValorUnitario).value() * quantity,
							unit_cost: numeral(numeral(element.ValorUnitario).value() * 1.16).format('0,0.00'),
						}
						// console.log('newElement--->', newElement);
						newElements.push(newElement)
					}
					setTimeout(() => {
						this.theOrder.elements = _.cloneDeep(newElements)
					}, 500)
				})
			})
		},
	},
	watch: {
		productsList: {
			deep: true,
			handler(newData) {
				// console.log('newData-->', newData)
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				}
				if (newData.length > 1) {
					this.modal.products.visible = true
				}
			},
		},
		actualImpressment(newData) {
			// console.log('watch::actualImpressment::newData-->', newData)
			if (utilities.objectValidate(newData, 'elements', false)) {
				for (let index = 0; index < newData.elements.length; index++) {
					const element = newData.elements[index]

					element.amount = numeral(element.price * element.quantity).format('0,0.00')
					element.price = numeral(element.price).format('0,0.00')
				}
				this.theOrder = _.cloneDeep(newData)

				if (newData.payment_method == 'PPD') {
					this.partialities = _.cloneDeep(JSON.parse(newData.ppd_payments))
					this.theOrder.partialities = this.partialities.length
				}

				setTimeout(() => {
					this.onSelectCustomer()
				}, 500)
			}
		},
		partialities(newData) {
			// console.log('newData-->', newData)
			if (newData) {
				this.partialitiesClass = newData.length == 2 ? 'col-md-6' : newData.length == 3 ? 'col-md-4' : 'col-md-3'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.table td,
.table th {
	padding: 0.25rem 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
</style>